.blue {
  color: #2596be !important;
}

.c-button {
  margin: 5px 2px 5px 2px !important;
}

.c-manager {
  width: 325px;
}

.c-manager-div {
  width: 325px;
  display: inline-table;
  margin-top: 5px;
}

.c-manager-long {
  width: 650px;
}

.c-manager-payment {
  width: 185px;
}

.c-manager-status {
  width: 200px;
}

.c-view-config {
  width: 150px;
}

.c-view-config-long {
  width: 250px;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline;
}

.left-td {
  text-align: right !important;
  color: #2596be !important;
  font-weight: bold !important;
}

.logo {
  width: 120px;
  display: block;
  float: right;
}

.main-div {
  margin: 10px;
}

.main-login{
  margin: auto;
  width: 325px;
}

.MuiFormControlLabel-root {
  height: 20px;
}

.red {
  color: red !important;
}

.title {
  color: #2596be !important;
  text-align: center;
}

.title-left {
  color: #2596be !important;
}

.transaction-modal {
  position: absolute;
  top: 10%;
  left: 10%;
  overflow: scroll;
  height: 100%;
  display: block;
}